/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "data-processing-ui-guide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#data-processing-ui-guide",
    "aria-label": "data processing ui guide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Data Processing UI Guide"), "\n", React.createElement(_components.h2, {
    id: "this-guide-describes-how-you-can-use-the-current-ui-to-specify-your-data-processing-operation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#this-guide-describes-how-you-can-use-the-current-ui-to-specify-your-data-processing-operation",
    "aria-label": "this guide describes how you can use the current ui to specify your data processing operation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "This Guide describes how you can use the current UI to specify your Data Processing Operation."), "\n", React.createElement(_components.h2, {
    id: "get-started",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-started",
    "aria-label": "get started permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get started"), "\n", React.createElement(_components.p, null, "To start specifying your Data Processing Operation, go to “Data Processing” in the Signatu menu bar."), "\n", React.createElement(_components.p, null, "Select “Dashboard”."), "\n", React.createElement(_components.h2, {
    id: "make-a-data-processing-group",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#make-a-data-processing-group",
    "aria-label": "make a data processing group permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Make a Data Processing Group"), "\n", React.createElement(_components.p, null, "Click on the “plus” icon to make a ", React.createElement(_components.strong, null, "Data Processing Group"), "."), "\n", React.createElement(_components.p, null, "Write a Group Name, e.g. “Website”."), "\n", React.createElement(_components.p, null, "Write a Group Description, e.g. “Website measurement”"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "What is a Data Processing Group?")), "\n", React.createElement(_components.p, null, "A Data Processing Group:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "contains one or more Data Processing Specifications."), "\n", React.createElement(_components.li, null, "are used to bundle together Data Processing Specifications that are related, e.g. website processing activities for a website."), "\n", React.createElement(_components.li, null, "can be used to build a consent dashboard."), "\n", React.createElement(_components.li, null, "can be integrated in a Privacy Policy as a section (in the Policy generator on Step 2). Se the ", React.createElement(_components.a, {
    href: "policyuiguide"
  }, "Policy UI Guide"), "."), "\n", React.createElement(_components.li, null, "can be copied to a Processing Record."), "\n", React.createElement(_components.li, null, "can be copied to an Access Request Response."), "\n"), "\n", React.createElement(_components.p, null, "This ensures that all these documents are kept in sync."), "\n", React.createElement(_components.p, null, "Click on the “plus” icon to make a new ", React.createElement(_components.strong, null, "Data Processing Group"), "."), "\n", React.createElement(_components.h2, {
    id: "create-a-data-processing-specification",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#create-a-data-processing-specification",
    "aria-label": "create a data processing specification permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Create a Data Processing Specification"), "\n", React.createElement(_components.p, null, "To create a ", React.createElement(_components.strong, null, "Data Processing Specification"), " in a ", React.createElement(_components.strong, null, "Data Processing Group"), ", click on the “plus” icon in the lower right corner."), "\n", React.createElement(_components.h2, {
    id: "structure-data-processing-operation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#structure-data-processing-operation",
    "aria-label": "structure data processing operation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Structure Data Processing Operation"), "\n", React.createElement(_components.p, null, "Your Data Processing Operation can be structured or authored completely by yourself (see below)."), "\n", React.createElement(_components.p, null, "To structure your Data Processing Operation, fill in and/or select:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Type of data, e.g. “IP address, browser string and website use”."), "\n", React.createElement(_components.li, null, "Data Processing Action, e.g. “collect”."), "\n", React.createElement(_components.li, null, "Purpose of processing, e.g. “measure (track, analyse and report) how users interact with website of website operator.”"), "\n", React.createElement(_components.li, null, "Legal basis of purpose, e.g. “consent”."), "\n", React.createElement(_components.li, null, "Location of processing, e.g. “EU”."), "\n", React.createElement(_components.li, null, "if the data are Sensitive."), "\n", React.createElement(_components.li, null, "if you want to set your Data Processing Specification as a Consent Target."), "\n"), "\n", React.createElement(_components.p, null, "Click OK."), "\n", React.createElement(_components.p, null, "To specify a new Data Processing Operation, click on the “plus” icon in the lower right corner."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NB!"), " You have a duty to provide one processing purpose only in a single consent request."), "\n", React.createElement(_components.p, null, "See our ", React.createElement(_components.a, {
    href: "gdprconsentguide"
  }, "GDPR Consent Legal Guide"), "."), "\n", React.createElement(_components.h2, {
    id: "edit-structured-data-processing-operation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#edit-structured-data-processing-operation",
    "aria-label": "edit structured data processing operation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Edit structured Data Processing Operation"), "\n", React.createElement(_components.p, null, "To edit a structured Data Processing Operation, click on “EDIT”."), "\n", React.createElement(_components.h2, {
    id: "author-your-own-free-form-text-data-processing-operation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#author-your-own-free-form-text-data-processing-operation",
    "aria-label": "author your own free form text data processing operation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Author your own free-form text Data Processing Operation"), "\n", React.createElement(_components.p, null, "To author your own text for your Data Processing Operation, click on “TEXTS”."), "\n", React.createElement(_components.p, null, "Then click on the “plus” icon in the window that opens."), "\n", React.createElement(_components.p, null, "Then write a text that you want to present to your customers."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NB!"), " Take care that the text fully describes the formal requirements of the Data Processing activity - i.e.:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "the purpose of the processing,"), "\n", React.createElement(_components.li, null, "the Data Processing action(s),"), "\n", React.createElement(_components.li, null, "location of processing,"), "\n", React.createElement(_components.li, null, "etc."), "\n"), "\n", React.createElement(_components.h2, {
    id: "view-data-processing-operation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#view-data-processing-operation",
    "aria-label": "view data processing operation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "View Data Processing Operation"), "\n", React.createElement(_components.p, null, "To view your Data Processing Operation, go to “Data Processing” in the Signatu menu bar."), "\n", React.createElement(_components.p, null, "Select “Specifications”."), "\n", React.createElement(_components.p, null, "Here, you can:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Click on the “Search” icon to search through your specifications."), "\n", React.createElement(_components.li, null, "Click on the “Download” icon to download a csv of your specifications."), "\n", React.createElement(_components.li, null, "Click on the “Pearch” icon to print your specifications."), "\n", React.createElement(_components.li, null, "Click on the “Columns” icon to show columns of your specifications."), "\n", React.createElement(_components.li, null, "Click on the “Filter” icon to filter your specifications."), "\n"), "\n", React.createElement(_components.h2, {
    id: "consent-ui-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#consent-ui-components",
    "aria-label": "consent ui components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Consent UI components"), "\n", React.createElement(_components.p, null, "Consent UI components can use a Data Processing Specification as a target for consent, as identified by the endpoint URL for the specification."), "\n", React.createElement(_components.p, null, "See our ", React.createElement(_components.a, {
    href: "/docs/api/v0/dataprocessing"
  }, "Technical Data Processing Guide"), "."), "\n", React.createElement(_components.p, null, "Based on the target, the Signatu SDK can automatically build a UI for the end-user request."), "\n", React.createElement(_components.p, null, "See our ", React.createElement(_components.a, {
    href: "/docs/api/v0/sdk"
  }, "Software Development Kit (SDK)"), " that gives you tools to build integrations to Signatu using the React UI library."), "\n", React.createElement(_components.h2, {
    id: "timestamp",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#timestamp",
    "aria-label": "timestamp permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Timestamp"), "\n", React.createElement(_components.p, null, "Your Data Processing Specification is automatically timestamped."), "\n", React.createElement(_components.h2, {
    id: "version-control",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#version-control",
    "aria-label": "version control permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Version control"), "\n", React.createElement(_components.p, null, "Your Data Processing Specification is automatically versioned."), "\n", React.createElement(_components.p, null, "Signatu keeps track of up to 100 previous versions."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
